import React from "react";
import Products from "../components/Products/index";
import Layout from "../components/Layout";
import MyHelmet from "../context/MyHelmet";

export default () => {
  return (
    <div>
      <Layout>
        <MyHelmet page="products" language="en" />
        <Products data={[]} />
      </Layout>
    </div>
  );
};
